import React, { useState } from "react";
import Chat from "../../../assets/images/chat";
import { FaRegTrashAlt } from "react-icons/fa";


const ContentCard = ({ content, setTab, onDelete }) => {

  console.log("forum_id: ", content.forum_id);

  const handleDelete = async () => {
    try {
      const response = await fetch(`https://spasateli.kz/api/admin/forum/delete/${content.forum_id}`, {
        method: 'DELETE'
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        onDelete(content.forum_id);
      }
    } catch (error) {
      console.log(`delete forum error: ${error}`);
    }
  };

  return (
    <div className="w-full h-[60px] flex items-center relative">
      <div className="bg-white border w-full border-gray-200 rounded-lg px-4 py-2 flex items-center justify-between">
        <div className="flex items-center w-full">
          <div
            className="flex-shrink-0 cursor-pointer"
            onClick={() => setTab({ type: 5, id: content.forum_id })}
          >
            <Chat className="w-10 h-10 mr-3" />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row">
              <h3 className="ml-2 text-sm font-semibold">{content.title}</h3>
              <div className="flex flex-row items-end ml-auto"></div>
            </div>
            <div className="flex flex-row w-full">
              <p className="ml-3 text-xs text-gray-500">
                {content.forum_author_name} {content.forum_author_surname}
              </p>
              <div className="flex ml-auto items-center">
                <p className="text-xs text-gray-500 text-ce">
                  {new Date(content.created_at).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button onClick={() => handleDelete()} className="hover:opacity-50 ml-4">
          <FaRegTrashAlt className="text-xl text-[#E13737]" />
        </button>
      </div>
    </div>
  );
};

export default ContentCard;
