import React, { useEffect, useState } from "react";
import DeleteWord from "./../../../assets/images/deleteword";
import AddWord from "./../../../assets/images/addword";
import ReactLoading from 'react-loading';
import { IoMdClose } from "react-icons/io";


export default function WordFilter() {
  const [words, setWords] = useState([]);
  const [inputWord, setInputWord] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchBanWords();
  }, []);

  const fetchBanWords = async () => {
    try {
      setIsLoading(true)
      const response = await fetch('https://spasateli.kz/api/admin/ban-words');

      const responseJson = await response.json();

      if (responseJson.success) {
        setWords(responseJson.list);
      }
    } catch (error) {
      console.log('fetch ban words: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addBanWord = async () => {
    try {
      const response = await fetch('https://spasateli.kz/api/admin/ban-words/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title: inputWord })
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        fetchBanWords();
        setInputWord("");
        setMessage();
      } else {
        setMessage(responseJson.message);
      }
    } catch (error) {
      console.log('add ban word: ', error);
    }
  };

  const deleteBanWord = async (id) => {
    try {
      const response = await fetch(`https://spasateli.kz/api/admin/ban-words/delete/${id}`, {
        method: 'DELETE'
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        fetchBanWords();
        if (words.length === 1) setWords([]);
      }
    } catch (error) {
      console.log('delete ban word: ', error);
    }
  }


  useEffect(() => {
    if (inputWord.includes(",")) {
      if (inputWord) {
        setWords([...words, inputWord.replace(",", "")]);
      }
      setInputWord("");
    }
  }, [inputWord]);
  return (
    <div className="mx-auto w-1/3 flex flex-col">
      {isLoading && (
        <div className="flex justify-center items-center">
          <ReactLoading type="spin" height='6%' width='6%' color="#E13737" />
        </div>
      )}
      {!isLoading && (
        <>
          <span className="text-lg text-center font-semibold">
            Запрещенные слова
          </span>
          <div className="mt-8 rounded-lg w-full border p-4 flex flex-wrap gap-4">
            {words.map((word, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-2 w-max border-2 rounded-xl shadow-lg"
              >
                <span>{word.title}</span>
                <button
                  onClick={() => deleteBanWord(word.id)}
                >
                  <DeleteWord />
                </button>
              </div>
            ))}
            <div className="flex items-center justify-between p-2 w-max border rounded-2xl bg-[#E13737] text-white outline-none">
              <input
                type="text"
                placeholder="Введите слово..."
                value={inputWord}
                onChange={(e) => setInputWord(e.target.value)}
                className="placeholder:text-white bg-[#E13737] outline-none"
              />
            </div>
          </div>
          <p className="text-center text-lg mt-2 text-[#E13737]">{message}</p>
          <div className="flex justify-center">
            <button
              onClick={() => addBanWord()}
              disabled={!inputWord}
              className={`bg-[#E13737] text-white px-32 py-2 rounded-2xl mt-4 ${!inputWord && 'opacity-50'}`}
            >
              Сохранить
            </button>
          </div>
        </>
      )}
    </div>
  );
}
