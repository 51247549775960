import React, { useState, useEffect } from "react";
import axios from "axios";
import MessageCard from "../Messages/MessageCard";
import ThemeTopicCard from "./ThemeTopicCard";
import ReadMoreButton from "./ReadMoreButton";

const ExpandedForumTheme = ({ forumId }) => {
  const [forum, setForum] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchForumData();
    fetchMessages();
  }, [forumId]);

  console.log('forum id: ', forumId);

  const fetchForumData = async () => {
    try {
      const response = await fetch(`https://spasateli.kz/api/admin/forums/${forumId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        setForum(responseJson.data);
      };
    } catch (error) {
      console.error("Error fetching forum data:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      console.log("fetchMessages forum id: ", forumId)

      const response = await fetch(`https://spasateli.kz/api/admin/forums/${forumId}/messages`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        console.log('comments: ', responseJson.comments);
        setMessages(responseJson.comments);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const onDelete = (id) => {
    // Удаление сообщения с заданным forum_message_id
    const updatedMessages = messages.filter(message => message.forum_message_id !== id);
    setMessages(updatedMessages);
  };

  if (!forum) {
    return <p>Loading...</p>;
  }


  return (
    <div className="p-4">
      <div className="max-w-6xl mx-auto flex">
        <div className="bg-white mb-4 w-[30%]">
          <ThemeTopicCard
            content={{
              author: `${forum.name} ${forum.surname}`,
              role: forum.type,
              date: new Date(forum.created_at).toLocaleString(),
              title: forum.title,
              description: forum.description,
              photo: forum.photo
            }}
          />
        </div>

        <div className="flex flex-wrap w-[70%]">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-2 px-7 ml-8">
            {messages && messages.map((message, index) => (
              <MessageCard
                key={index}
                content={{
                  author: `${message.message_user_name} ${message.message_user_surname}`,
                  time: new Date(message.created_at).toLocaleDateString(),
                  role: message.message_user_type,
                  content: message.content,
                  photo: message.message_user_photo,
                  forum_message_id: message.forum_message_id,
                  forum_id: message.forum_id
                }}
                onDelete={onDelete}
              />
            ))}
          </div>
          {/* <ReadMoreButton /> */}
        </div>
      </div>
    </div>
  );
};

export default ExpandedForumTheme;
