import React, { useState } from "react";
import axios from "axios";
import MoreVertical from "./../../../assets/images/morevertical";

const MessageCard = ({ content, onDelete }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  console.log("MessageCard: ", content);

  const handleDelete = async () => {
    try {
      const response = await fetch('https://spasateli.kz/api/admin/forum/messages', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          forum_id: content.forum_id,
          comment_id: content.forum_message_id
        })
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        onDelete(content.forum_message_id);
        setMenuVisible(false);
      }
    } catch (error) {
      console.error("Failed to delete the message:", error);
    }
  };

  return (
    content && (
      <div className="w-full h-max flex items-center relative">
        <div className="bg-white border w-full border-gray-200 rounded-lg px-4 py-2 flex items-center justify-between ">
          <div className="flex items-center w-full">
            <div className="flex-shrink-0 cursor-pointer self-start">
              <img
                className="w-10 h-10 rounded-full"
                src={`https://spasateli.kz/api/user/avatar/${content.photo}`}
                alt="avatar"
              />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row">
                <h3 className="ml-2 text-sm font-semibold">
                  {content.author}
                </h3>
                <div className="flex flex-row items-end ml-4">
                  <p className="text-xs text-gray-500">
                    {content.time}
                  </p>
                </div>
              </div>
              <div className="flex flex-row w-full">
                <p className="ml-3 text-xs text-gray-500">{content.role}</p>
                <div className="flex ml-auto"></div>
              </div>
              <div className="w-full">
                <p className=" ml-2 mt-2 text-sm text-gray-500">
                  {content.content}
                </p>
              </div>
            </div>
          </div>
        </div>
        <MoreVertical toggleMenu={toggleMenu} />
        {menuVisible && (
          <div className="absolute right-0 top-20 w-40 text-md bg-white border rounded-lg shadow-xl z-10">
            <ul>
              <li
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600 text-center"
                onClick={handleDelete}
              >
                Удалить
              </li>
            </ul>
          </div>
        )}
      </div>
    )
  );
};

export default MessageCard;
